import React, { useRef } from "react";
import { graphql } from "gatsby";
import Layouts from "../../components/Layouts";
import { IntroProducts } from "../../components/Screens/Products/IntroProducts";
import { NanoLoansProducts } from "../../components/Screens/Products/NanoLoans";
import { StoreLinks } from "../../components/StoreLinks";
import { BannerCard } from "../../components/Screens/Products/BannerCard";
import { Cta } from "../../components/Cta";
import Seo from "../../components/Seo";
import { CreditSection } from "../../components/Screens/Products/CreditSection";
import { DiscountButton } from "../../components/Atomics/DiscountButton";
import NanoLoansSteps from "../../components/Screens/Products/NanoLoansSteps";

const ProductsPageArabic = ({ data }) => {
	const stepsCards = [
		{
			title: `${data.page.nanoLoansProductsAr.workingstepsAr.stepOne}`,
			children: (
				<StoreLinks
					googleLink={
						data.page.nanoLoansProductsAr.workingstepsAr.googlePlayLink
					}
					appGalleryLink={
						data.page.nanoLoansProductsAr.workingstepsAr.appGalleryLink
					}
				/>
			),
		},
		{
			title: `${data.page.nanoLoansProductsAr.workingstepsAr.stepTwoTitle}`,
			text: `${data.page.nanoLoansProductsAr.workingstepsAr.stepTwoText}`,
		},
		{
			title: `${data.page.nanoLoansProductsAr.workingstepsAr.stepThreeTitle}`,
			text: `${data.page.nanoLoansProductsAr.workingstepsAr.stepThreeText}`,
		},
		{
			title: `${data.page.nanoLoansProductsAr.workingstepsAr.stepFourTitle}`,
			text: `${data.page.nanoLoansProductsAr.workingstepsAr.stepFourText}`,
			children: (
				<a
					href={`${data.page.nanoLoansProductsAr.workingstepsAr.stepFourButtonLink}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<button className="py-5 px-8 rounded-2xl bg-[#F6B904] flex items-center text-white  rtl:text-right mt-8">
						<span className="ml-20">إطلب تمويل دلوقتي</span>
						<svg
							width="7"
							height="12"
							viewBox="0 0 7 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className="rtl:rotate-180"
						>
							<path
								d="M1 1L6 6L1 11"
								stroke="white"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</button>
				</a>
			),
		},
	];
	const productsRef = useRef();

	const handleScrollDown = () => {
		productsRef.current.scrollIntoView({ behavior: "smooth" });
	};
	return (
		<>
			<Seo title="منتجات" />

			<Layouts lang={"ar"} dir="rtl">
				<IntroProducts
					header={
						<div className="xl:w-8/12  mx-auto">
							{data.page.heroProductsAr.pageHeaderProductsAr.header}
						</div>
					}
					text={data.page.heroProductsAr.pageHeaderProductsAr.text}
					googleLink={
						data.page.heroProductsAr.pageHeaderProductsAr.googlePlayLink
					}
					appGalleryLink={
						data.page.heroProductsAr.pageHeaderProductsAr.appGalleryLink
					}
					handleScrollDown={handleScrollDown}
				/>
				<div ref={productsRef}>
					<NanoLoansProducts
						miniHeader={
							data.page.nanoLoansProductsAr.featuresProductsAr.miniHeader
						}
						header={data.page.nanoLoansProductsAr.featuresProductsAr.header}
						image={data.page.nanoLoansProductsAr.featuresProductsAr.stickyImage}
						textCards={
							data.page.nanoLoansProductsAr.featuresProductsAr.features
						}
						stepsCards={stepsCards}
						stepsHeader="خطوات بسيطة .. تمويلات سريعة "
						stepsMiniHeader="إزاي تسحب تمويل من كاشات"
						id="nano-loans"
					/>
				</div>
				<BannerCard
					bgColor
					Icon={TAG}
					header={data.page.bannersProductsAr.bannerOneAr.title}
					text={data.page.bannersProductsAr.bannerOneAr.text}
				/>
				{/* <BannerCard
					border
					Icon={PEOPLE}
					header={
						<div className="w-10/12 lg:w-full  xl:w-9/12 2xl:w-7/12 mx-auto">
							{data.page.bannersProductsAr.bannerTwoAr.title}
						</div>
					}
					children={
						<DiscountButton
							text={data.page.bannersProductsAr.bannerTwoAr.text}
							link={data.page.bannersProductsAr.bannerTwoAr.promoCodeLink}
							buttonText="إحصل علي الكود دلوقتي"
						/>
					}
				/> */}
				{/* <CreditSection
					id="prepaid-cards"
					miniHeader={
						data.page.prepaidCardsProductsAr.featuresPrepaidcardsProductsAr
							.miniHeader
					}
					header={
						data.page.prepaidCardsProductsAr.featuresPrepaidcardsProductsAr
							.header
					}
					textHeader={
						data.page.prepaidCardsProductsAr.featuresPrepaidcardsProductsAr
							.benefits.header
					}
					debitTextCards={
						data.page.prepaidCardsProductsAr.featuresPrepaidcardsProductsAr
							.benefits.benefits
					}
					faturesHeader={
						data.page.prepaidCardsProductsAr.featuresPrepaidcardsProductsAr
							.usesPrepaidcardProducts.header
					}
					depitFeatures={
						data.page.prepaidCardsProductsAr.featuresPrepaidcardsProductsAr
							.usesPrepaidcardProducts.uses
					}
					depitImage={
						data.page.prepaidCardsProductsAr.featuresPrepaidcardsProductsAr
							.prepaidcardImageProducts
					}
					lang="ar"
				/> */}
				<Cta
					lang="ar"
					miniHeader="أول مقدم للخدمات المالية متناهية الصغر في الشرق الأوسط"
					header="مهمتنا تمكين الوصول الى الإمكانيات المالية الرقمية"
					subHeader="كاشات هي أول خطوة للشمول المالي للمصريين الغير متعاملين مع البنوك عن طريق توفير تمويلات انتاجية قصيرة المدى وسريعة"
				/>
			</Layouts>
		</>
	);
};
export default ProductsPageArabic;

export const pageQu = graphql`
	query ArabicProducts {
		page: wpPage(databaseId: { eq: 372 }) {
			title
			heroProductsAr {
				pageHeaderProductsAr {
					header
					text
					googlePlayLink
					appGalleryLink
				}
			}
			nanoLoansProductsAr {
				featuresProductsAr {
					miniHeader
					header
					stickyImage {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1920, placeholder: BLURRED)
							}
						}
					}
					features {
						title
						text
						addImage
						image {
							sourceUrl
						}
					}
				}
				workingstepsAr {
					stepOne
					googlePlayLink
					appGalleryLink
					stepTwoTitle
					stepTwoText
					stepThreeTitle
					stepThreeText
					stepFourTitle
					stepFourText
					stepFourButtonLink
				}
			}
			bannersProductsAr {
				bannerOneAr {
					title
					text
				}
				bannerTwoAr {
					title
					text
					promoCodeLink
				}
			}
			prepaidCardsProductsAr {
				featuresPrepaidcardsProductsAr {
					miniHeader
					header
					benefits {
						header
						benefits {
							title
						}
					}
					prepaidcardImageProducts {
						sourceUrl
					}
					usesPrepaidcardProducts {
						header
						uses {
							icon {
								sourceUrl
							}
							title
						}
					}
				}
			}
		}
	}
`;

const TAG = (
	<svg
		width="72"
		height="72"
		viewBox="0 0 72 72"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="72" height="72" rx="16" fill="#202020" fill-opacity="0.2" />
		<g clip-path="url(#clip0_429_4886)">
			<path
				d="M52.0417 31.3684C51.5367 30.9617 50.8367 30.8884 50.255 31.1784L41.635 35.4884L37.43 28.4784C36.8284 27.4734 35.1734 27.4734 34.5717 28.4784L30.3667 35.4884L21.745 31.1767C21.1634 30.8867 20.4634 30.96 19.9584 31.3667C19.45 31.7767 19.2267 32.44 19.3817 33.0717L22.3634 44.9934C21.54 45.6017 21 46.57 21 47.6684V49.335C21 51.1734 22.4934 52.6684 24.3334 52.6684H47.6667C49.5067 52.6684 51 51.1734 51 49.335V47.6684C51 46.57 50.46 45.6017 49.6367 44.995L52.6184 33.0734C52.7734 32.44 52.55 31.7767 52.0417 31.3684ZM30.255 39.16C31.0334 39.5484 31.9817 39.2734 32.43 38.5267L36 32.5734L39.5717 38.525C40.02 39.2717 40.9684 39.5467 41.7467 39.1584L48.505 35.78L46.3667 44.335H25.635L23.4967 35.78L30.255 39.16ZM47.6617 49.335H24.3334V47.6684H47.6667L47.6617 49.335Z"
				fill="#12022F"
			/>
			<path
				d="M36.0001 26.0016C37.841 26.0016 39.3334 24.5092 39.3334 22.6683C39.3334 20.8273 37.841 19.335 36.0001 19.335C34.1591 19.335 32.6667 20.8273 32.6667 22.6683C32.6667 24.5092 34.1591 26.0016 36.0001 26.0016Z"
				fill="#12022F"
			/>
		</g>
		<defs>
			<clipPath id="clip0_429_4886">
				<rect
					width="40"
					height="40"
					fill="white"
					transform="translate(16 16)"
				/>
			</clipPath>
		</defs>
	</svg>
);
const PEOPLE = (
	<svg
		width="72"
		height="72"
		viewBox="0 0 72 72"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			y="0.000244141"
			width="72"
			height="72"
			rx="24"
			fill="#F5B803"
			fill-opacity="0.1"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M27.9108 37.0043C28.4609 36.955 28.9468 37.3609 28.9961 37.911C29.0454 38.4611 28.6394 38.947 28.0894 38.9963C27.8045 39.0218 27.5239 39.0675 27.2341 39.1375L26.9407 39.2156C26.4429 39.3423 26.1428 39.5369 26.0617 39.7586C25.9795 39.9823 25.9795 40.2574 26.0626 40.484C26.1084 40.6095 26.4075 40.8329 27.0347 41.0601C27.5539 41.2481 27.8224 41.8215 27.6344 42.3408C27.4463 42.8601 26.8729 43.1286 26.3536 42.9405C25.225 42.5318 24.4767 41.9728 24.1843 41.171C23.9386 40.5012 23.9386 39.7374 24.184 39.0699C24.5185 38.1558 25.2663 37.6242 26.2086 37.3431L26.4134 37.2867C26.9017 37.1439 27.4022 37.0499 27.9108 37.0043ZM43.9726 37.0006L44.0894 37.0043C44.4962 37.0408 44.8979 37.1082 45.2747 37.2021L45.5524 37.2773C46.6243 37.5501 47.4571 38.0902 47.8168 39.0701C48.0611 39.738 48.0611 40.5006 47.8167 41.1704C47.4597 42.1459 46.6415 42.6783 45.5642 42.9664C45.0307 43.109 44.4825 42.7922 44.3398 42.2586C44.1971 41.7251 44.514 41.1769 45.0475 41.0343C45.5589 40.8975 45.8581 40.7028 45.9382 40.4839C46.0206 40.2581 46.0206 39.9816 45.9389 39.7582C45.8578 39.5372 45.5573 39.3423 45.0251 39.2062C44.6627 39.1002 44.2905 39.0304 43.9108 38.9963C43.3607 38.947 42.9547 38.4611 43.0041 37.911C43.0499 37.4002 43.4721 37.0137 43.9726 37.0006ZM36 38.0003C31.5186 38.0003 29.0001 39.2596 29.0001 42.0003C29.0001 44.741 31.5186 46.0003 36 46.0003C40.4815 46.0003 43.0001 44.741 43.0001 42.0003C43.0001 39.2596 40.4815 38.0003 36 38.0003ZM36 40.0003C39.5186 40.0003 41.0001 40.741 41.0001 42.0003C41.0001 43.2596 39.5186 44.0003 36 44.0003C32.4815 44.0003 31.0001 43.2596 31.0001 42.0003C31.0001 40.741 32.4815 40.0003 36 40.0003Z"
			fill="#F5B803"
		/>
		<g opacity="0.5">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M36 26.0002C33.2277 26.0002 31 28.2277 31 30.9998C31 33.7726 33.2275 36.0002 36 36.0002C38.7725 36.0002 41 33.7726 41 30.9998C41 28.2277 38.7723 26.0002 36 26.0002ZM36 28.0002C37.6678 28.0002 39 29.3323 39 30.9998C39 32.6681 37.6679 34.0002 36 34.0002C34.3321 34.0002 33 32.668 33 30.9998C33 29.3323 34.3322 28.0002 36 28.0002ZM29 28.0002C29.5523 28.0002 30 28.448 30 29.0002C30 29.5525 29.5523 30.0002 29 30.0002C27.8957 30.0002 27 30.8959 27 32.0002C27 33.0544 27.8161 33.9184 28.8508 33.9948L29 34.0002C29.5523 34.0002 30 34.448 30 35.0002C30 35.5525 29.5523 36.0002 29 36.0002C26.7912 36.0002 25 34.2092 25 32.0002C25 29.7912 26.7912 28.0002 29 28.0002ZM43 28.0002C45.2088 28.0002 47 29.7912 47 32.0002C47 34.2093 45.2088 36.0002 43 36.0002C42.4477 36.0002 42 35.5525 42 35.0002C42 34.4874 42.386 34.0647 42.8834 34.007L43 34.0002C44.1043 34.0002 45 33.1046 45 32.0002C45 30.8958 44.1043 30.0002 43 30.0002C42.4477 30.0002 42 29.5525 42 29.0002C42 28.448 42.4477 28.0002 43 28.0002Z"
				fill="#F5B803"
			/>
		</g>
	</svg>
);
